<template>
  <div>
    <b-img
      v-if="!path && !localImage"
      fluid
      :src="active
        ? require('@/assets/images/placeholders/image-placeholder-drop.svg')
        : require('@/assets/images/placeholders/image-placeholder.svg')"
      @dragenter.prevent="toggleActive"
      @dragleave.prevent="toggleActive"
      @dragover.prevent
      @drop.prevent="onDropImage"
      @click="onSelectImage"
    />
    <b-row v-if="path && !localImage">
      <b-col
        cols="12"
        class="text-center"
      >
        <b-img
          class="image-fit"
          fluid
          thumbnail
          :src="path"
        />
      </b-col>
      <b-col class="text-center">
        <b-button
          v-b-tooltip.hover.bottom="'Eliminar imagen'"
          class="btn-icon rounded-circle inside-button"
          variant="flat-danger"
          @click="onDeleteImage"
        >
          <feather-icon
            icon="Trash2Icon"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="localImage">
      <b-col
        cols="12"
        class="text-center"
      >
        <b-img
          class="image-fit"
          fluid
          thumbnail
          :src="localImage"
        />
      </b-col>
      <b-col class="text-center">
        <b-button
          v-b-tooltip.hover.bottom="'Eliminar imagen'"
          class="btn-icon rounded-circle inside-button"
          variant="flat-danger"
          @click="onDeleteImage"
        >
          <feather-icon
            icon="Trash2Icon"
          />
        </b-button>
      </b-col>
    </b-row>
    <input
      v-show="false"
      ref="imageSelector"
      type="file"
      accept="image/png,image/jpeg,image/jpg"
      @change="onSelectedImage"
    >
  </div>
</template>

<script>
import { dataUrlToFile } from '@/helpers/DataUrlToFile'
import {
  BCol, BRow, BButton, VBTooltip, BImg,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const VALID_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
const VALID_IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.png']

export default {
  components: {
    BImg,
    BCol,
    BRow,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    image: {
      type: [Object, File],
      default: null,
    },
    /**
     * Verifica si se debe validar el tamaño de la imagen
     */
    validateSize: {
      type: Boolean,
      default: false,
    },
    /**
     * Verifica si se debe aceptar el formato webp
     */
    acceptWebpFormat: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
      localImage: null,
      file: null,
      path: null,
    }
  },
  async created() {
    if (this.image) {
      this.path = this.image.image
      await this.setImagePathToFile()
    }
  },
  mounted() {
    if (this.image) {
      // await this.setImagePathToFile()
    }
  },
  methods: {
    onDropImage(event) {
      const file = event.dataTransfer.files[0]
      if (this.validImage(file)) {
        this.processImage(file)
      }
    },
    onSelectedImage(event) {
      const file = event.target.files[0]
      if (this.validImage(file)) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = ''
        this.processImage(file)
      }
    },
    validImage(file) {
      if (this.validateSize && file.size > 2000000) {
        this.showToast(
          'Error de validación',
          'El tamaño del archivo es incorrecto. Tamaño máximo permitido 2MB',
          'danger',
        )

        return false
      }

      if (this.acceptWebpFormat && !VALID_IMAGE_TYPES.includes('image/webp')) {
        VALID_IMAGE_TYPES.push('image/webp')
      }

      if (!file.type || !VALID_IMAGE_TYPES.includes(file.type)) {
        // Verifica que se pueda aceptar el formato webp y que no exista en la lista de extensiones
        if (this.acceptWebpFormat && !VALID_IMAGE_EXTENSIONS.includes('.webp')) {
          VALID_IMAGE_EXTENSIONS.push('.webp')
        }

        const errorTypeMessage = `El formato del archivo es incorrecto. Formatos permitidos ${VALID_IMAGE_EXTENSIONS.join(', ')}`
        this.showToast('Error de validación', errorTypeMessage, 'danger')

        return false
      }

      return true
    },
    processImage(file) {
      this.active = false

      if (!file) {
        return
      }

      this.file = file
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.localImage = fileReader.result
      }

      fileReader.readAsDataURL(file)
      this.$emit('image-loaded', 1)
    },
    onSelectImage() {
      this.$refs.imageSelector.click()
    },
    onDeleteImage() {
      this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La imagen seleccionada será eliminada del formulario!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminarla!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.localImage = null
          this.file = null
          this.path = null
          this.$emit('image-deleted', 1)
        }
      })
    },
    toggleActive() {
      this.active = !this.active
    },
    async setImagePathToFile() {
      const file = await dataUrlToFile(this.image.imagesCode, this.image.image)
      this.file = file
      this.$emit('image-loaded', 1)
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.inside-button {
  margin-top: .5em;
}

.image-fit{
  width: 140px;
  height: 140px;
  object-fit: cover;
}
</style>
