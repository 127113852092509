<template>
  <b-card class="blue-card">
    <b-card-text>
      <b-row>

        <!-- #region::Column for products count -->
        <b-col md="2">
          <InformationBasicCard
            title="Productos"
            :tags="[{ value: refund.devolution.Qty, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for products count -->

        <!-- #region::Column for location -->
        <b-col md="2">
          <InformationBasicCard
            title="Ubicación"
            :tags="[{ value: refund.devolution.Location, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for location -->

        <b-row />
      </b-row></b-card-text>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    InformationBasicCard,
  },
  computed: {
    /**
     * Getters del estado de devoluciones
     */
    ...mapGetters({
      getRefund: 'refunds/getRefund',
    }),
    /**
     * Información de la devolución
     */
    refund: {
      get() { return this.getRefund },
    },
  },
}
</script>
