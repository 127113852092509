<template>
  <div v-if="canShowRefundRejectComment">
    <b-card
      no-body
      class="border border-dark shadow-none"
    >
      <b-card-body>
        <b-card-text class="d-flex justify-content-right">
          <div>
            <avatar
              :username="rejectData.publisher"
              background-color="#0053D3"
              color="#fff"
            />
          </div>
          <div class="ml-2">
            <h4 class="font-weight-bolder">
              {{ rejectData.publisher }}
            </h4>
            <h6>{{ rejectData.comment }}</h6>
            <small class="text-muted">{{ rejectData.date | moment("DD/MM/YYYY") }}</small>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BCardText, BCardBody,
} from 'bootstrap-vue'

import Avatar from 'vue-avatar'

export default {
  components: {
    BCard,
    Avatar,
    BCardText,
    BCardBody,
  },
  computed: {
    /**
     * Getters del estado de devoluciones
     */
    ...mapGetters({
      getRefund: 'refunds/getRefund',
    }),
    /**
     * Información de la devolución
     */
    refund: {
      get() { return this.getRefund },
    },
    status: {
      get() { return this.refund.devolution.status.status },
    },
    rejectData: {
      get() { return this.refund.rejectComment },
    },
    canShowRefundRejectComment() {
      return this.status === 'Rechazada' && this.rejectData
    },
  },
}
</script>
