<template>
  <div>
    <b-row>
      <b-col
        v-if="$ability.can('read total_quotation', 'Order')"
        md="4"
      >
        <b-card class="border border-dark shadow-none">
          <div>
            <b-row>

              <!-- #region::Title -->
              <b-col class="align-self-end">
                <h4 class="font-weight-bolder">
                  Total de la devolución
                </h4>
              </b-col>
              <!-- #endregion::Title -->

              <!-- #region begin::Collapse card button -->
              <b-button
                :variant="visible ? 'flat-primary' : 'flat-secondary'"
                class="btn-icon rounded-circle mr-1"
                @click="collapse"
              >
                <feather-icon :icon="iconToggle" />
              </b-button>
              <!-- #endregion end::Collapse card button -->

            </b-row>
          </div>

          <b-collapse
            :id="collapseItemId"
            v-model="visible"
            role="tabpanel"
          >
            <div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal USD</span>
                <span>{{ refund.devolution.SubtotalUsd | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Tipo de cambio</span>
                <span>{{ refund.devolution.ExchangeRate | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal MXN</span>
                <span>{{ refund.devolution.SubtotalMxn | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Descuento</span>
                <span v-if="refund.devolution.Discount">{{ `${refund.devolution.Discount}%` || '0%' }}</span>
                <span v-else>0.00%</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Total MXN</span>
                <span><strong>{{ refund.devolution.TotalMxn | currency }}</strong></span>
              </div>
            </div>
          </b-collapse>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton, BCollapse,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
  },
  data() {
    return {
      visible: false,
      quoteInfo: null,
      collapseItemId: '4',
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getRefund: 'refunds/getRefund',
    }),
    refund: {
      get() { return this.getRefund },
    },
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
