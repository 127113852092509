<template>
  <div>

    <template v-if="isLoadingPayments">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="230px"
      />
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Payments list table -->
          <b-table
            v-if="availablePayments"
            :items="payments"
            :fields="columns"
            responsive
            class="my-0"
          >

            <!-- #region::Used credit balance -->
            <template #cell(amount)="{ item }">
              {{ item.amount | currency }}
            </template>
            <!-- #endregion::Used credit balance -->

            <!-- #region::Created at date -->
            <template #cell(created_at)="{ item }">
              <template v-if="item.created_at">
                <div class="text-nowrap">
                  {{ item.created_at | moment("DD/MM/YYYY") }}
                </div>
              </template>
              <span
                v-else
                class="text-nowrap"
              >Sin especificar</span>
            </template>
          <!-- #endregion::Created at date -->

          </b-table>
          <!-- #endregion::Payments list table -->

          <!-- #region::Alert when no transfers are available -->
          <b-alert
            v-if="!availablePayments && !isLoadingPayments"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron pagos.</span>
            </div>
          </b-alert>
        <!-- #endregion::Alert when no transfers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availablePayments"
      ref="basic-paginator"
      class="mt-2 mb-1"
      :per-page-col="3"
      :total-rows="totalPayments"
      :callback="handleChangePagination"
      :can-apply-global-pagination="false"
    />
    <!-- #endregion end::Pagination & items per list -->

  </div>
</template>

<script>
// #region Imports
import { mapActions } from 'vuex'
import {
  BCard, BCardText, BTable, BSkeleton, BAlert,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #rendegion Imports

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BCardText,
    BSkeleton,
    BasicPaginator,
  },
  props: {
    /**
     * Identificador del modelo, puede ser el id de la orden o de la devolución
     */
    modelId: {
      type: Number,
      required: true,
    },
    /**
     * Tipo de modelo, puede ser order o devolución
     */
    modelType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastPage: 0,
      perPage: 10,
      indexFrom: 0,
      currentPage: 1,
      totalPayments: 0,
      /**
       * Columnas para la lista de pagos
       */
      columns: [
        {
          key: 'ID',
          label: 'ID',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'amount',
          label: 'Saldo utilizado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'created_at',
          label: 'Registrado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
      ],
      payments: [],
      isLoadingPayments: false,
    }
  },
  computed: {
    availablePayments() {
      return this.totalPayments > 0
    },
  },
  created() {
    this.loadUsedCreditBalancePaymentsList()
  },
  methods: {
    ...mapActions({
      loadUsedCreditBalancePayments: 'orders/loadUsedCreditBalancePayments',
    }),

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.perPage = perPage
      this.currentPage = currentPage
      this.loadUsedCreditBalancePaymentsList()
    },

    async loadUsedCreditBalancePaymentsList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingPayments = true

        const response = await this.loadUsedCreditBalancePayments({
          type: this.modelType,
          id: this.modelId,
          loadParams: this.loadParams(),
        })

        this.payments = response.data.data.data
        this.lastPage = response.data.data.last_page
        this.indexFrom = response.data.data.from
        this.totalPayments = response.data.data.total

        this.isLoadingPayments = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.perPage,
        currentPage: this.currentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
