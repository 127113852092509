<template>
  <div>
    <b-card
      class="mt-1 border border-dark shadow-none"
      no-body
    >
      <b-card-text>

        <b-table
          :items="refundPayments"
          :fields="columns"
          responsive
          class="my-0"
        >
          <!-- #region::Virtual column for status -->
          <template #cell(PaymentConfirmation)="{ item }">
            <div class="d-flex">
              <span
                class="dot-cell align-self-center"
                :class="[paymentStatusDotColor(item.PaymentConfirmation)]"
              /><span>{{ item.PaymentConfirmation }}</span>
            </div>
          </template>
          <!-- #endregion::Virtual column for status -->

          <!-- #region::Virtual column for count -->
          <template #cell(Payment)="{ item }">
            {{ item.Payment | currency }}
          </template>
          <!-- #endregion::Virtual column for count -->

          <!-- #region::Virtual column for exchange type -->
          <template #cell(ExchangeRate)="{ item }">
            <template v-if="item.ExchangeRate">
              {{ item.ExchangeRate | currency }}
            </template>
            <span
              v-else
              class="text-nowrap"
            >Sin especificar</span>
          </template>
          <!-- #endregion::Virtual column for exchange type -->

          <!-- #region::Virtual column for payment method -->
          <template #cell(paymentMethod)="{ item }">
            <span class="text-nowrap">{{ item.method.method }}</span>
          </template>
          <!-- #endregion::Virtual column for payment method -->

          <!-- #region::Virtual column for total -->
          <template #cell(TotalMXN)="{ item }">
            {{ item.TotalMXN | currency }}
          </template>
          <!-- #endregion::Virtual column for total -->

          <!-- #region::Virtual column for date payment -->
          <template #cell(Date)="{ item }">
            {{ item.Date | moment("DD/MM/YYYY") }}
          </template>
          <!-- #endregion::Virtual column for date payment -->

          <!-- #region::Virtual column for confirm/cancel payment -->
          <template #cell(PaymentStatus)="{ item }">
            <template v-if="canShowActionsButtons(item.PaymentStatus)">
              <div class="d-flex">

                <!-- #region::Confirm payment button -->
                <b-button
                  v-if="$ability.can('confirm refund', 'Devolution')"
                  variant="principal-btn"
                  class="principal-btn"
                  size="sm"
                  :disabled="(loadingPaymentConfirmation && selectedPaymentId === item.IdPaymentDevolution) || !canManageRefundPayments"
                  @click="onConfirmPayment(item)"
                >
                  <b-spinner
                    v-if="loadingPaymentConfirmation && selectedPaymentId === item.IdPaymentDevolution"
                    class="mr-50"
                    small
                  />
                  <span class="align-middle">Confirmar</span>
                </b-button>
                <!-- #endregion::Confirm payment button -->

                <!-- #region::Cancel payment button -->
                <b-button
                  v-if="$ability.can('cancel refund', 'Devolution')"
                  variant="delete-btn"
                  class="delete-btn ml-1"
                  size="sm"
                  :disabled="(loadingPaymentCancelation && selectedPaymentId === item.IdPaymentDevolution) || !canManageRefundPayments"
                  @click="onCancelPayment(item)"
                >
                  <b-spinner
                    v-if="loadingPaymentCancelation && selectedPaymentId === item.IdPaymentDevolution"
                    class="mr-50"
                    small
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
                <!-- #endregion::Cancel payment button -->

              </div>

            </template>

            <!-- #region::Label for TO CONFIRM payment status -->
            <template v-else-if="canShowStatusLabel(item.PaymentStatus)">
              <div class="d-flex">
                <span
                  class="dot-cell align-self-center"
                  :class="[paymentStatusDotColor(item.PaymentStatus)]"
                /><span>{{ item.PaymentStatus }}</span>
              </div>
            </template>
            <!-- #endregion::Label for payment status -->

          </template>
          <!-- #endregion::Virtual column for confirm/cancel payment -->

          <!-- #region::A virtual column for preview/download -->
          <template #cell(receipt)="{ item }">

            <div class="d-flex justify-content-around">

              <!-- #region::Preview image button -->
              <b-button
                v-b-tooltip.hover.top="'Ver imagen'"
                variant="flat-primary"
                class="btn-icon"
                @click="$refs.imagePreviewModal.show(item.Image)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <!-- #endregion::Preview image button -->

              <!-- #region::Download receipt button -->
              <!-- #region::Preview image button -->
              <b-button
                v-b-tooltip.hover.top="'Descargar imagen'"
                variant="flat-success"
                class="btn-icon"
                @click="downloadImage(item.IdPaymentDevolution)"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <!-- #endregion::Preview image button -->
              <!-- #endregion::Download receipt button -->
            </div>

          </template>
          <!-- #endregion::A virtual column for preview/download -->

          <!-- #region::Virtual column for created date -->
          <template #cell(created_at)="{ item }">
            {{ item.created_at | moment("DD/MM/YYYY") }}
          </template>
          <!-- #endregion::Virtual column for created date -->

          <!-- #region::A virtual column for actions -->
          <template #cell(actions)="{ item }">

            <template v-if="canEditPayment(item)">

              <!-- #region::Edit payment button -->
              <b-button
                id="editPaymentButton"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                :disabled="(loadingPaymentForm && selectedPaymentId === item.id) || !canManageRefundPayments"
                @click="onUpdatePayment(item)"
              >
                <b-spinner
                  v-if="loadingPaymentForm && selectedPaymentId === item.id"
                  small
                />
                <feather-icon
                  v-else
                  icon="Edit3Icon"
                />
              </b-button>
              <b-tooltip
                target="editPaymentButton"
                placement="top"
                triggers="hover"
              >
                Editar
              </b-tooltip>
              <!-- #endregion::Edit payment button -->

            </template>

          </template>
          <!-- #endregion::A virtual column for actions -->

        </b-table>

      </b-card-text>
    </b-card>

    <RefundPaymentModal
      v-if="$ability.can('register refund', 'Devolution')"
      ref="refundPaymentModal"
      @reload-refund="$emit('reload-refund')"
    />

    <ImagePreviewModal ref="imagePreviewModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BCard, BCardText, BTable, BButton, BSpinner, VBTooltip, BTooltip,
} from 'bootstrap-vue'

import getError from '@/helpers/ErrorsHandler'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
import ImagePreviewModal from '@/components/modals/ImagePreviewModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RefundPaymentModal from '@/modules/store/refunds/components/modals/RefundPaymentModal.vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BSpinner,
    BTooltip,
    BCardText,
    ImagePreviewModal,
    RefundPaymentModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      columns: [
        {
          key: 'PaymentConfirmation',
          label: 'Estatus',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'ID',
          label: 'ID',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Invoice',
          label: 'Folio de recibo',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'Payment',
          label: 'Cantidad',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'ExchangeRate',
          label: 'T.C.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'paymentMethod',
          label: 'Forma de pago',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'TotalMXN',
          label: 'Total',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Date',
          label: 'Comprobante',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'PaymentStatus',
          label: 'Confirmar pago',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'receipt',
          label: 'Recibo',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'created_at',
          label: 'Registrado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: '',
          thStyle: 'text-transform: none;',
          thClass: this.$ability.can('edit refund', 'Devolution') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('edit refund', 'Devolution') ? '' : 'd-none',
        },
      ],

      selectedPaymentId: '',
      loadingPaymentForm: false,
      loadingPaymentCancelation: false,
      loadingPaymentConfirmation: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getRefundPayments: 'refunds/getRefundPayments',
    }),
    /**
     * Lista de pagos de la devolución
     */
    refundPayments: {
      get() { return this.getRefundPayments },
    },
    /**
     * Indica si puede gestionar los pagos de la devolución
     */
    canManageRefundPayments() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  methods: {
    ...mapActions({
      downloadImage: 'refunds/downloadImage',
      cancelPayment: 'refunds/cancelPayment',
      confirmPayment: 'refunds/confirmPayment',
    }),

    /**
    * Confirmación de pago
    *
    * @summary Cofirma el pago de la devolución y actualiza el estatus del pago
    * @param {Object} payment - Objeto con la información del pago
    * @exception Muestra un mensaje de error si no se puede confirmar el pago
    */
    async onConfirmPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.loadingPaymentConfirmation = true

        const formData = new FormData()
        formData.append('idPaymentDevolution', payment.IdPaymentDevolution)

        const response = await this.confirmPayment(formData)

        if (response.status === 200) {
          this.$set(payment, 'PaymentStatus', 'Confirmado')
          this.$set(payment, 'PaymentConfirmation', 'Pagado')

          this.showToast(
            'Pago confirmado',
            'El pago seleccionado ha sido confirmado correctamente.',
            'success',
          )

          this.$emit('reload-refund')
        }

        this.loadingPaymentConfirmation = false
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Cancelación de pago
    *
    * @summary Cofirma el pago de la devolución y actualiza el estatus del pago
    * @param {Object} payment - Objeto con la información del pago
    * @exception Muestra un mensaje de error si no se puede confirmar el pago
    */
    async onCancelPayment(payment) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.loadingPaymentCancelation = true

        const formData = new FormData()
        formData.append('idPaymentDevolution', payment.IdPaymentDevolution)

        const response = await this.cancelPayment(formData)

        if (response.status === 200) {
          this.$set(payment, 'PaymentStatus', 'Cancelado')
          this.$set(payment, 'PaymentConfirmation', 'Cancelado')

          this.showToast(
            'Pago cancelado',
            'El pago seleccionado ha sido cancelado correctamente.',
            'success',
          )

          this.$emit('reload-refund')
        }

        this.loadingPaymentCancelation = false
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    async onUpdatePayment(payment) {
      this.selectedPaymentId = payment.id
      this.$root.$emit('bv::hide::tooltip', 'editPaymentButton')
      this.loadingPaymentForm = true

      await this.$refs.refundPaymentModal.showModalAndLoadExistingPayment(payment)

      this.loadingPaymentForm = false
    },

    /**
    * Edición de pago
    *
    * @summary Determina si el pago puede ser editado
    * @param {Object} payment - Objeto con la información del pago
    */
    canEditPayment(payment) {
      const statuses = ['Pendiente', 'Cancelado']
      return this.$ability.can('edit refund', 'Devolution')
        && statuses.includes(payment.PaymentConfirmation)
    },

    /**
    * Estatus de pago
    *
    * @summary Determina el color del punto de estatus del pago
    * @param {String} status - Estatus del pago
    */
    paymentStatusDotColor(status) {
      switch (status) {
        case 'Pendiente':
        case 'Por confirmar':
          return 'dot-cell-blue'
        case 'Pagado':
        case 'Confirmado':
          return 'dot-cell-green'
        case 'Cancelado':
          return 'dot-cell-red'
        default:
          return 'dot-cell-blue'
      }
    },

    /**
     * Acciones de elementos
     *
     * @summary Determina si se pueden mostrar los botones de acciones (confirmar, cancelar)
     * @param {String} status - Estatus del pago
     */
    canShowActionsButtons(status) {
      return status === 'Por confirmar'
        && (
          this.$ability.can('confirm refund', 'Devolution')
            || this.$ability.can('cancel refund', 'Devolution')
        )
    },

    /**
     * Etiquetas de estados
     *
     * @summary Determina si se pueden mostrar las etiquetas de estatus
     * @param {String} status - Estatus del pago
     */
    canShowStatusLabel(status) {
      const statuses = ['Por confirmar', 'Confirmado', 'Cancelado']
      return statuses.includes(status)
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
