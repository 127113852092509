<template>
  <div class="modal-history">
    <b-button
      v-b-modal="'modal-tall'"
      variant="flat-primary"
    >
      <feather-icon
        icon="ClockIcon"
        class="mr-50"
      />
      <span class="align-middle">Historial de estatus</span>
    </b-button>
    <!-- The modal -->
    <b-modal
      id="modal-tall"
      :title="title"
      hide-footer
    >

      <!-- #region::Quotes data -->
      <div
        v-for="(quote, index) in quotes"
        :key="index"
        class="d-flex justify-content-between mt-2 history"
      >
        <div>
          <h3 class="text-nowrap">
            Cotización #{{ quote.quote }}
          </h3>
          <p class="mb-0">
            <strong>{{ quote.user }}</strong>
          </p>
          <p class="mb-0">
            <small>{{ quote.role }}</small>
          </p>
          <p class="mb-0">
            {{ quote.date }}
          </p>
          <p class="mb-0">
            {{ quote.time }}
          </p>
          <p
            v-if="quote.comment"
            class="mb-0 mt-1"
          >
            <strong>Comentario</strong>
          </p>
          <p class="mb-0">
            {{ quote.comment }}
          </p>
          <div class="space" />
        </div>
        <div class="ml-auto">
          <p class="text-nowrap">
            Cambio de estatus:
          </p>
          <div
            class="banner-alert m-0"
            :class="[statusColorStyle(quote.status)]"
            style="white-space: nowrap; font-size: 12px; text-align: center; width: fit-content;"
            role="alert"
          >
            {{ quote.status }}
          </div>
        </div>
      </div>
      <!-- #endregion::Quotes data -->

      <!-- #region::Orders data -->
      <div
        v-for="order, index in orders"
        :key="`${index}${order.order}`"
        class="d-flex justify-content-between mt-2 history"
      >
        <div>
          <h3 v-if="index == 0">
            Orden #{{ order.order }}
          </h3>
          <p class="mb-0">
            <strong>{{ order.user }}</strong>
          </p>
          <p class="mb-0">
            <small>{{ order.role }}</small>
          </p>
          <p class="mb-0">
            {{ order.date }}
          </p>
          <p class="mb-0">
            {{ order.time }}
          </p>
          <p
            v-if="order.comment"
            class="mb-0 mt-1"
          >
            <strong>Comentario</strong>
          </p>
          <p class="mb-0">
            {{ order.comment }}
          </p>
          <div class="space" />
        </div>
        <div class="ml-auto ">
          <p class="text-nowrap">
            Cambio de estatus:
          </p>
          <div
            class="banner-alert m-0"
            :class="[statusColorStyle(order.status)]"
            style="white-space: nowrap; font-size: 12px; text-align: center; width: fit-content;"
            role="alert"
          >
            {{ order.status }}
          </div>
        </div>
      </div>
      <!-- #endregion::Orders data -->

      <!-- #region::Transfers data -->
      <div
        v-for="(transfer, index) in transfers"
        :key="index"
        class="d-flex justify-content-between mt-2 history"
      >
        <div>
          <h3 v-if="index == 0">
            Traspaso #{{ transfer.transfer }}
          </h3>
          <p class="mb-0">
            <strong>{{ transfer.user }}</strong>
          </p>
          <p class="mb-0">
            <small>{{ transfer.role }}</small>
          </p>
          <p class="mb-0">
            {{ transfer.date }}
          </p>
          <p class="mb-0">
            {{ transfer.time }}
          </p>
          <p
            v-if="transfer.comment"
            class="mb-0 mt-1"
          >
            <strong>Comentario</strong>
          </p>
          <p class="mb-0">
            {{ transfer.comment }}
          </p>
          <div class="space" />
        </div>
        <div class="ml-auto ">
          <p class="text-nowrap">
            Cambio de estatus:
          </p>
          <div
            class="banner-alert m-0"
            :class="[statusColorStyle(transfer.status)]"
            style="white-space: nowrap; font-size: 12px; text-align: center; width: fit-content;"
            role="alert"
          >
            {{ transfer.status }}
          </div>
        </div>
      </div>
      <!-- #endregion::Transfers data -->

      <!-- #region::Refunds data -->
      <div
        v-for="(refund, index) in refunds"
        :key="index"
        class="d-flex justify-content-between mt-2 history"
      >
        <div>
          <h3 v-if="index == 0">
            Devolución #{{ refund.devolution }}
          </h3>
          <p class="mb-0">
            <strong>{{ refund.user }}</strong>
          </p>
          <p class="mb-0">
            <small>{{ refund.role }}</small>
          </p>
          <p class="mb-0">
            {{ refund.date }}
          </p>
          <p class="mb-0">
            {{ refund.time }}
          </p>
          <p
            v-if="refund.comment"
            class="mb-0 mt-1"
          >
            <strong>Comentario</strong>
          </p>
          <p class="mb-0">
            {{ refund.comment }}
          </p>
          <div class="space" />
        </div>
        <div class="ml-auto ">
          <p class="text-nowrap">
            Cambio de estatus:
          </p>
          <div
            class="banner-alert m-0"
            :class="[statusColorStyle(refund.status)]"
            style="white-space: nowrap; font-size: 12px; text-align: center; width: fit-content;"
            role="alert"
          >
            {{ refund.status }}
          </div>
        </div>
      </div>
      <!-- #endregion::Refunds data -->

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'

export default {
  name: 'OrderModalHistory',
  components: {
    BModal,
    BButton,
  },
  props: {
    history: {
      type: Object,
      default: () => ({
        status: 'Orden',
      }),
    },
    title: {
      type: String,
      default: 'Historial de estatus',
    },
  },
  data() {
    return {
      historyInfo: null,
      quotes: null,
      orders: null,
      refunds: null,
      transfers: null,
    }
  },
  watch: {
    history() {
      this.loadHistoryData()
    },
  },
  created() {
    this.loadHistoryData()
  },
  methods: {
    statusColorStyle(status) {
      switch (status) {
        case ('Orden' || 'Órden'):
          return 'alert-green-border'
        case 'Aprobada':
          return 'alert-green'
        case 'En bodega':
          return 'alert-purple'
        case 'En suministro':
          return 'alert-orange'
        case 'Lista para entrega':
          return 'alert-yellow'
        case 'Traspaso en proceso':
          return 'alert-yellow'
        case 'Detenida':
          return 'alert-gray'
        case 'Cancelada':
          return 'alert-gray'
        case 'Cancelado':
          return 'alert-gray'
        case 'Traspaso':
          return this.transfers ? 'alert-pink' : 'alert-marine'
        case 'Solicitud':
          return 'alert-purple'
        case 'Rechazada':
          return 'alert-orange'
        case 'Listo para traspaso':
          return 'alert-marine'
        case 'En transito':
          return 'alert-yellow-black'
        case 'Recibida':
          return 'alert-blue'
        case 'Traspaso aprobado':
          return 'alert-green'
        case 'Traspasada':
          return 'alert-green-border'
        default:
          return 'alert-blue'
      }
    },
    loadHistoryData() {
      // const [historyInfo] = this.history
      // this.historyInfo = historyInfo
      this.quotes = this.history?.quote
      this.orders = this.history?.order
      this.refunds = this.history?.devolution
      this.transfers = this.history?.transfer
    },
  },
}
</script>

<style lang="scss" >

.modal{
  #modal-tall___BV_modal_content_{
    padding: 16px!important;
  }
}
.space {
  width: 1px;
  height: 30px;
  background-color: rgb(146, 146, 146);
  margin-top: 10px;
}
.history{
  &:last-child{
    .space{
      display: none;
    }
  }
}

</style>
