// eslint-disable-next-line import/prefer-default-export
export const dataUrlToFile = async (dataUrl, fileName) => {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]

  const res = await fetch(dataUrl)
  const blob = await res.blob()

  return new File([blob], fileName, { type: mime })
}
