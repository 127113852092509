const convertImageToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onerror = reject
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.readAsDataURL(blob)
})

export default convertImageToBase64
