<template>
  <div>
    <!-- #region begin::Form -->
    <validation-observer ref="rejectingRefundCommentModalForm">
      <b-form @submit="$event.preventDefault()">

        <b-modal
          id="rejecting-refund-comment-modal"
          ref="rejecting-refund-comment-modal"
          title="Devolución rechazada"
          centered
        >
          <b-card-text>
            <b-row>
              <b-col>
                <h6><p>Comenta el motivo del rechazo</p></h6>
              </b-col>

              <!-- #region::Comment textarea -->
              <b-col md="12">
                <b-form-group
                  label="Comentario"
                  label-for="textarea-comment"
                >

                  <div
                    slot="label"
                    class="d-flex justify-content-start"
                  >
                    <span>Comentario</span>
                    <h5 class="text-danger mb-0">
                      *
                    </h5>
                  </div>

                  <validation-provider
                    #default="{ errors }"
                    name="comentario"
                    vid="Comentario"
                    rules="required"
                  >

                    <b-form-textarea
                      id="textarea-comment"
                      v-model="comment"
                      placeholder="Escribe el comentario"
                      rows="3"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion::Comment textarea -->

            </b-row>
          </b-card-text>

          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="hideModal"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              :disabled="savingComment"
              @click="onSaveComment"
            >
              <b-spinner
                v-if="savingComment"
                class="mr-50"
                small
              />
              <span class="align-middle">Aceptar</span>
            </b-button>
          </template>
          <!-- #endregion::Footer -->

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// #region Imports
import { mapActions } from 'vuex'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import {
  BCol, BRow, BModal, VBModal, BCardText, BForm, BFormTextarea, BFormGroup, BButton,
  BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BRow,
    BForm,
    BModal,
    BButton,
    BSpinner,
    BCardText,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      refundId: '',
      comment: '',
      savingComment: false,

      // * 05/10/2022 - TAG: Validations
      required,
    }
  },
  async created() {
    localize('es')
  },
  methods: {
    ...mapActions({
      rejectRefund: 'refunds/rejectRefund',
    }),
    async onSaveComment() {
      const success = await this.$refs.rejectingRefundCommentModalForm.validate()
      if (success) {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('comment', this.comment)
        formData.append('id', this.refundId)

        try {
          this.savingComment = true
          await this.rejectRefund(formData)

          this.showToast(
            'Devolución rechazada',
            'La devolución ha sido rechazada correctamente.',
            'success',
          )
          this.$swal.close()

          this.$emit('reload-refund')
          this.hideModal()
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.savingComment = false
        }
      }
    },
    showModal(refundId) {
      this.refundId = refundId
      this.$refs['rejecting-refund-comment-modal'].show()
    },
    hideModal() {
      this.$refs['rejecting-refund-comment-modal'].hide()
      this.comment = ''
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
