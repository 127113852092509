<template>
  <div>

    <!-- #region::Modal for showing image -->
    <b-modal
      id="image-preview-modal"
      ref="image-preview-modal"
      centered
      :title="title"
      ok-only
      ok-title="Aceptar"
    >
      <b-card-text>

        <!-- #region::Image -->
        <b-img
          v-if="imagePath"
          :src="imagePath"
          fluid-grow
          alt="Responsive image"
        />
      </b-card-text>
      <!-- #endregion::Image -->

    </b-modal>
    <!-- #endregion::Modal for showing image -->

  </div>
</template>

<script>
import {
  BModal, VBModal, BCardText, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BModal,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    /**
     * Título del modal
     */
    title: {
      type: String,
      default: 'Recibo de pago',
    },
  },
  data() {
    return {
      /**
       * Ruta de la imagen que será mostrada
       */
      imagePath: null,
    }
  },
  methods: {

    /**
    * Vista previa de la imagen
    * @summary Carga la ruta de la imagen y muestra el modal
    * @param {string} imageInfo - Ruta de la imagen que será mostrada
    */
    show(imagePath) {
      this.imagePath = imagePath
      this.$refs['image-preview-modal'].show()
    },
  },
}
</script>
