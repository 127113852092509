<template>
  <div>

    <!-- #region::Payment form -->
    <validation-observer ref="refundPaymentForm">
      <b-form @submit="$event.preventDefault()">

        <b-modal
          id="refund-payment-modal"
          ref="refund-payment-modal"
          :title="id ? 'Editar devolución de orden' : 'Devolución de orden'"
          centered
          @hidden="onCancelPayment"
        >
          <b-card-text>
            <b-row>

              <b-col md="12">
                <h5><p>{{ id ? 'Guardar cambios' : 'Registrar pago' }}</p></h5>
              </b-col>

              <b-col md="12">
                <div
                  slot="label"
                  class="d-flex justify-content-start"
                >
                  <small>Imagen del pago</small>
                  <h5 class="text-danger">
                    *
                  </h5>
                </div>
              </b-col>

              <!-- #region::Images inputs group -->
              <b-col
                md="3"
                class="mt-1"
              >
                <b-form-group>
                  <ImagesLoader
                    ref="img01"
                    :image="images[0]"
                    @image-loaded="imagesLoaded = true"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-alert
                  variant="danger"
                  :show="!imagesLoaded && submitted"
                  class="mb-2"
                >
                  <div class="alert-body">
                    <small>Es necesario agregar imagen del pago</small>
                  </div>
                </b-alert>
              </b-col>

              <b-col md="6">
                <small class="text-primary">Arrastra o selecciona la imagen</small>
              </b-col>
              <!-- #endregion::Images inputs group -->

              <!-- #region::Folio input -->
              <b-col md="12">
                <TextInputWithValidation
                  v-model="folio"
                  vid="folio"
                  rules="required"
                  type="text"
                  label="Folio"
                  :label-class="['red-dot']"
                  name="folio"
                  maxlength="250"
                  placeholder="Escribe el folio"
                />
              </b-col>
              <!-- #endregion::Folio input -->

              <!-- #region::Payment date picker -->
              <b-col md="12">
                <DatePickerWithValidation
                  v-model="paymentDate"
                  vid="paymentDate"
                  rules="required"
                  label="Fecha de comprobante"
                  :show-required-dot="true"
                  name="fecha de comprobante"
                  placeholder="Selecciona la fecha de comprobante"
                />
              </b-col>
              <!-- #endregion::Payment date picker -->

              <!-- #region::Payment method select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="selectedPaymentMethod"
                  vid="selectedPaymentMethod"
                  rules="required"
                  :label-class="['red-dot']"
                  name="forma de pago"
                  label="Forma de pago"
                  property="method"
                  placeholder="Selecciona"
                  :options="getPaymentMethods"
                />
              </b-col>
              <!-- #endregion::Payment method select -->

              <!-- #region::Count input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="count"
                  vid="count"
                  :rules="{
                    required: true,
                    negative,
                    notalpha,
                    decimal,
                    'max_value': 1000000000,
                  }"
                  type="text"
                  :label-class="['red-dot']"
                  label="Cantidad"
                  name="cantidad"
                  maxlength="12"
                  placeholder="1,000.00"
                  prepend="$"
                />
              </b-col>
              <!-- #endregion::Count input -->

              <!-- #region::USD payment checkbox -->
              <b-col
                md="6"
                offset-md="6"
              >
                <b-form-checkbox v-model="usdCount">
                  USD
                </b-form-checkbox>
              </b-col>
              <!-- #endregion::USD payment checkbox -->

              <!-- #region::Exchange type input -->
              <b-col md="12">
                <TextInputWithValidation
                  v-model="exchangeType"
                  vid="exchangeType"
                  :rules="{
                    required: usdCount,
                    negative,
                    notalpha,
                    decimal,
                  }"
                  type="text"
                  :label-class="[usdCount ? 'red-dot' : '']"
                  label="Tipo de cambio"
                  name="tipo de cambio"
                  maxlength="12"
                  placeholder="20.00"
                  prepend="$"
                  :disabled="true"
                />
              </b-col>
              <!-- #endregion::Exchange type input -->

              <!-- #region::Payment title -->
              <b-col md="12">
                <h6 class="font-weight-bolder">
                  <p>Pago</p>
                </h6>
              </b-col>
              <!-- #endregion::Payment title -->

              <!-- #region::Total MXN -->
              <b-col md="6">
                Total MXN
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ currentTotalMXNPayment || 0 | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Total MXN -->

              <!-- #region::Breakdown refund -->
              <b-col md="12">
                <h6 class="font-weight-bolder">
                  <p>Desglose de devolución</p>
                </h6>
              </b-col>
              <!-- #endregion::Breakdown refund -->

              <!-- #region::Subtotal USD -->
              <b-col md="6">
                Subtotal USD
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ subtotalUSD | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Subtotal USD -->

              <!-- #region::Exchange type -->
              <b-col md="6">
                Tipo de cambio
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ exchangeRate | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Exchange type -->

              <!-- #region::Subtotal MXN -->
              <b-col md="6">
                Subtotal MXN
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ subtotalMXN | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Subtotal MXN -->

              <!-- #region::Discount -->
              <b-col md="6">
                Descuento
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                <span v-if="discount">{{ `${discount}%` || '0%' }}</span>
                <span v-else>0.00%</span>
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Discount -->

              <!-- #region::Total MXN -->
              <b-col
                md="6"
                class="font-weight-bolder"
              >
                Total MXN
              </b-col>
              <b-col
                md="6"
                class="text-right font-weight-bolder"
              >
                {{ totalMXN | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Total MXN -->

            </b-row>
          </b-card-text>

          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="onCancelPayment"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              @click="onRegisterPayment"
            >
              {{ id ? 'Guardar cambios' : 'Solicitar devolución' }}
            </b-button>
          </template>
          <!-- #endregion::Footer -->

        </b-modal>
      </b-form>
    </validation-observer>
    <!-- #region::Payment form -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import {
  required, decimal, notalpha, negative, maxValue,
} from '@validations'
import {
  BForm, BModal, VBModal, BCardText, BRow, BCol, BFormGroup, BAlert, BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

import ImagesLoader from '@/components/forms/ImagesLoader.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
import convertImageToBase64 from '@/helpers/ConvertImageToBase64'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BModal,
    BAlert,
    BButton,
    BCardText,
    BFormGroup,
    ImagesLoader,
    BFormCheckbox,
    ValidationObserver,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      id: null,
      folio: '',
      count: '',
      images: [],
      paymentDate: '',
      usdCount: false,
      exchangeType: '',
      selectedPaymentMethod: '',

      submitted: false,
      imagesLoaded: false,

      /**
       * Validaciones
       */
      decimal,
      required,
      notalpha,
      negative,
      maxValue,

      /**
       * Informacion del pago
       */
      payment: null,
    }
  },
  computed: {
    ...mapGetters({
      getRefund: 'refunds/getRefund',
      getPaymentMethods: 'refunds/getPaymentMethods',
      getSelectedRefundPayment: 'payments/getSelectedRefundPayment',
    }),
    /**
     * Orignes de informacion de pago para mostrar en el modal
     *
     * @example Si el pago se edita desde el detalle de la devolución, se muestra la información a
     * partir de la devolución. Si el pago se edita desde el listado de reportes de pagos, se
     * muestra la información a partir del pago seleccionado
     */
    refund: {
      get() { return this.getRefund },
    },
    selectedRefundPayment: {
      get() { return this.getSelectedRefundPayment },
    },
    /**
     * Id de la devolución
     */
    idDevolution: {
      get() { return this.refund?.devolution.IdDevolution || this.selectedRefundPayment?.IdDevolution },
    },
    /**
     * Subtotal USD que se muestra en el desglose de devolución
     */
    subtotalUSD: {
      get() { return this.refund?.devolution.SubtotalUsd || this.selectedRefundPayment?.SubtotalUsd },
    },
    /**
     * Tipo de cambio que se muestra en el desglose de devolución
     */
    exchangeRate: {
      get() { return this.refund?.devolution.ExchangeRate || this.selectedRefundPayment?.ExchangeRateDevolution },
    },
    /**
     * Subtotal MXN que se muestra en el desglose de devolución
     */
    subtotalMXN: {
      get() { return this.refund?.devolution.SubtotalMxn || this.selectedRefundPayment?.SubtotalMxn },
    },
    /**
     * Descuento que se muestra en el desglose de devolución
     */
    discount: {
      get() { return this.refund?.devolution.Discount || this.selectedRefundPayment?.Discount },
    },
    /**
     * Total MXN que se muestra en el desglose de devolución
     */
    totalMXN: {
      get() { return this.refund?.devolution.TotalMxn || this.selectedRefundPayment?.TotalMxn },
    },
    /**
     * Valor del pago en MXN calculado con el tipo de cambio en tiempo real
     */
    currentTotalMXNPayment() {
      if (this.usdCount) {
        return this.count * this.exchangeType
      }

      return this.count
    },
    paymentData() {
      return {
        id: this.id,
        invoice: this.folio,
        payment: this.count,
        date: this.paymentDate,
        exchangeRate: this.exchangeType || '',
        idDevolution: this.idDevolution,
        idRefundMethod: this.selectedPaymentMethod.IdRefundMethod,
      }
    },
  },
  watch: {
    usdCount() {
      if (!this.usdCount) {
        this.exchangeType = ''
      } else {
        this.exchangeType = this.exchangeRate
      }
    },
  },
  created() {
    localize('es')
  },
  methods: {
    ...mapActions({
      createPayment: 'refunds/createPayment',
      updatePayment: 'refunds/updatePayment',
      downloadImageBlob: 'refunds/downloadImageBlob',
      loadPaymentMethods: 'refunds/loadPaymentMethods',
      createRefundPaymentImage: 'refunds/createRefundPaymentImage',
    }),
    async onRegisterPayment() {
      const success = await this.$refs.refundPaymentForm.validate()
      this.submitted = true

      if (!this.$refs.img01.file) {
        this.imagesLoaded = false
        return
      }

      if (success) {
        if (this.totalMXN !== Number(this.currentTotalMXNPayment)) {
          this.showToast(
            'Error de validación',
            'El pago ingresado debe ser igual al valor del Total MXN',
            'danger',
          )
          return
        }

        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        formData.append('invoice', this.folio)
        formData.append('payment', this.count)
        formData.append('date', this.paymentDate)
        formData.append('exchangeRate', this.exchangeType || '')
        formData.append('idDevolution', this.idDevolution)
        formData.append('idRefundMethod', this.selectedPaymentMethod.IdRefundMethod)

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        try {
          const response = this.id
            ? await this.updatePayment(this.paymentData)
            : await this.createPayment(formData)

          // Verifica si la respuesta es correcta
          if (response.data.data) {
            const formImage = new FormData()
            formImage.append('files[]', this.$refs.img01.file)
            formImage.append('row', this.selectedRefundPayment ? 1 : 0)
            formImage.append('idPaymentDevolution', response.data.data)

            const responseImage = await this.createRefundPaymentImage(formImage)

            this.showSwalFire('¡La información del pago se ha registrado correctamente!', responseImage)
            this.hideModal()
          }
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    /**
     * Muestra el modal
     *
     * @summary Carga los métodos de pago y muestra el modal
     */
    async showModal() {
      await this.loadPaymentMethods()
      this.$refs['refund-payment-modal'].show()
    },

    /**
    * Información del pago
    * @summary Muestra el modal y carga la información del pago
    * @param {Object} payment - Información del pago
    */
    async showModalAndLoadExistingPayment(payment) {
      try {
        await this.loadPaymentMethods()

        this.id = payment.IdPaymentDevolution
        this.folio = payment.Invoice
        this.count = payment.Payment
        this.paymentDate = payment.Date
        this.usdCount = payment.ExchangeRate !== null
        this.exchangeType = payment.ExchangeRate

        this.selectedPaymentMethod = {
          IdRefundMethod: payment.method.IdRefundMethod,
          method: payment.method.method,
        }

        const blob = await this.downloadImageBlob(payment.IdPaymentDevolution)
        const base64 = await convertImageToBase64(blob)

        this.images = [{ imagesCode: base64, image: payment.Image }]
        this.$refs['refund-payment-modal'].show()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    async showModalAndLoadReportPayment(payment) {
      try {
        await this.loadPaymentMethods()

        this.id = payment.IdPaymentKey
        this.folio = payment.Invoice.value
        this.count = payment.Payment
        this.paymentDate = payment.VoucherDate
        this.usdCount = payment.ExchangeRate !== null
        this.exchangeType = payment.ExchangeRate

        this.selectedPaymentMethod = {
          IdRefundMethod: payment.IdRefundMethod,
          method: payment.PaymentMethod,
        }

        const blob = await this.downloadImageBlob(payment.IdPaymentKey)
        const base64 = await convertImageToBase64(blob)

        this.images = [{ imagesCode: base64, image: payment.Image }]
        this.$refs['refund-payment-modal'].show()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
     * Cancelación de pago
     *
     * @summary Limpia los campos del formulario y oculta el modal
     */
    onCancelPayment() {
      this.folio = ''
      this.count = ''
      this.images = []
      this.paymentDate = ''
      this.usdCount = false
      this.exchangeType = ''
      this.submitted = false
      this.selectedPaymentMethod = ''
      this.hideModal()
    },

    /**
     * Cierre de modal
     *
     * @summary Oculta el modal
     */
    hideModal() {
      this.$refs['refund-payment-modal'].hide()
    },

    showSwalFire(message, responseImage) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (this.selectedRefundPayment) {
            this.$emit('update-payment', responseImage.data.data.report)
          } else {
            this.$emit('reload-refund')
          }
        }
      })
    },

    /**
    * Mensaje de notificación
    *
    * @summary Muestra un mensaje de notificación
    * @param {String} title - Título del mensaje
    * @param {String} text - Texto del mensaje
    * @param {String} variant - Tipo del mensaje
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
