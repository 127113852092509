<template>
  <div>
    <b-row>

      <!-- #region::Positive balance card -->
      <b-col v-if="refund.devolution.CreditBalance !== undefined">
        <IconDetailsCard :card="positiveBalanceCard" />
      </b-col>
      <!-- #endregion::Positive balance card -->

      <!-- #region::Used positive balance card -->
      <b-col>
        <IconDetailsCard :card="usedPositiveBalanceCard">

          <template slot="subtext">
            <h5 class="text-muted align-self-end">
              ({{ refund.devolution.RemainingCreditBalance | currency }})
            </h5>
          </template>

          <!-- #region::Add pieces to circuit button -->
          <template slot="action">
            <b-button
              size="sm"
              variant="flat-primary align-self-center"
              class="mt-0"
              @click="$refs.orderUsedCreditBalanceModal.showModal()"
            >
              <span>Ver detalles</span>
            </b-button>
          </template>
          <!-- #endregion::Add pieces to circuit button -->
        </IconDetailsCard>
      </b-col>
      <!-- #endregion::Used positive balance card -->

      <b-col
        v-if="refundPayments.length > 0"
        md="12"
      >
        <RefundPaymentsHistoryList @reload-refund="$emit('reload-refund')" />
      </b-col>

    </b-row>

    <OrderUsedCreditBalanceModal
      v-if="existUsedCreditBalance"
      ref="orderUsedCreditBalanceModal"
      :model-id="refund.devolution.IdDevolution"
      :model-type="'refund'"
      :payer-name="payerName"
      :used-credit-balance="refund.devolution.UsedCreditBalance"
      :remainig-credit-balance="refund.devolution.RemainingCreditBalance"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCol, BRow, BButton } from 'bootstrap-vue'

import IconDetailsCard from '@/components/cards/IconDetailsCard.vue'
import RefundPaymentsHistoryList from '@/modules/store/refunds/components/lists/RefundPaymentsHistoryList.vue'
import OrderUsedCreditBalanceModal from '@/modules/trade/orders/components/modals/OrderUsedCreditBalanceModal.vue'

import validateCurrency from '@/helpers/CurrencyFormat'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    IconDetailsCard,
    RefundPaymentsHistoryList,
    OrderUsedCreditBalanceModal,
  },
  computed: {
    ...mapGetters({
      getRefund: 'refunds/getRefund',
      getFilteredSortBy: 'filters/getFilteredSortBy',
      getRefundPayments: 'refunds/getRefundPayments',
    }),
    /**
     * Información global de la devolución
     */
    refund: {
      get() { return this.getRefund },
    },
    /**
     * Lista de pagos de la devolución
     */
    refundPayments: {
      get() { return this.getRefundPayments },
    },
    positiveBalanceCard() {
      return {
        icon: 'ClockIcon',
        title: 'Saldo a favor',
        text: validateCurrency(this.refund.devolution.CreditBalance),
      }
    },
    usedPositiveBalanceCard() {
      return {
        icon: 'ClockIcon',
        title: 'Saldo a favor usado',
        text: validateCurrency(this.refund.devolution.UsedCreditBalance),
      }
    },
    existUsedCreditBalance() {
      return this.refund.devolution.UsedCreditBalance !== null
    },
    /**
     * Nombre del responsable de los pagos. Si es vendedor o mayorista,
     * se toma el nombre del usuario logueado, de lo contrario, se toma el nombre del cliente
     */
    payerName() {
      const statuses = ['Vendedor', 'Mayorista']
      const userData = JSON.parse(localStorage?.userData)

      if (statuses.includes(userData.Role)) {
        return `${userData?.Name} ${userData?.LastName || ''}`
      }

      return this.refund.devolution.user.Name
    },
  },
}
</script>
