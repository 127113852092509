<template>
  <div>
    <b-card
      no-body
      class="border border-dark shadow-none my-1"
    >
      <b-card-body>
        <b-card-title class="d-flex justify-content-between">
          <h4 class="font-weight-bolder">
            {{ card.title }}
          </h4>
          <slot name="action" />
        </b-card-title>
        <b-card-text class="d-flex justify-content-right">
          <div
            v-if="card.styles"
            class="d-flex justify-content-center align-items-center rounded-circle icon-card mr-1"
            :class="card.styles"
          >
            <feather-icon
              :icon="card.icon"
              class="image-icon w-50 h-50"
            />
          </div>
          <div class="d-flex">
            <h3 class="align-self-center mr-25">
              {{ card.text }}
            </h3>
            <slot name="subtext" />
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .icon-card {
    width: 50px;
    height: 50px;
  }
</style>
