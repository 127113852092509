<template>
  <div>
    <b-form-row align-v="center">

      <!-- #region::Return to orders list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small>
          <router-link
            :to="{ name: 'refunds-list' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return to orders list link -->

      <!-- #region::Title with tag -->
      <b-col>
        <div class="d-flex justify-content-left">
          <h3 class="align-self-center">
            Devolución #{{ refund.devolution.ID }}
          </h3>
          <div
            :class="['banner-alert', statusColorStyle]"
            role="alert"
          >
            {{ status }}
          </div>
          <div>
            <OrderModalHistory
              v-if="historyStatus"
              :history="historyStatus"
            />
          </div>
        </div>
      </b-col>
      <!-- #endregion::Title with tag -->

      <!-- #region::Download pdf button -->
      <b-col
        v-if="canShowDownloadPdfButton"
        cols="12"
        md="auto"
        :disabled="downloadingFile"
      >
        <b-button
          variant="flat-success"
          class="csv-btn"
          @click="onDownloadPDF"
        >
          <b-spinner
            v-if="downloadingFile"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!downloadingFile"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">Descargar PDF</span>
        </b-button>
      </b-col>
      <!-- #endregion::Download pdf button -->

      <!-- #region::Cancel refund button -->
      <b-col
        v-if="canShowCancelRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="cancelingRefund"
          @click="onCancelRefund"
        >
          <b-spinner
            v-if="cancelingRefund"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel refund button -->

      <!-- #region::Reject refund button -->
      <b-col
        v-if="canShowRejectRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="rejectingRefund"
          @click="onRejectRefund"
        >
          <b-spinner
            v-if="rejectingRefund"
            class="mr-50"
            small
          />
          <span class="align-middle">Rechazar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Reject refund button -->

      <!-- #region::Confirm refund autcoming button -->
      <b-col
        v-if="canShowApproveRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="approvingRefund"
          @click="onApproveRefund"
        >
          <b-spinner
            v-if="approvingRefund"
            class="mr-50"
            small
          />
          <span class="align-middle">Confirmar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm refund autcoming button -->

      <!-- #region::Make refund payment button button -->
      <b-col
        v-if="canShowRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="$refs.refundPaymentModal.showModal()"
        >
          <span class="align-middle">Reembolsar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Make refund payment button button -->

      <!-- #region::Deleting refund button -->
      <b-col
        v-if="canShowDeleteRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onDeleteRefund"
        >
          Eliminar
        </b-button>
      </b-col>
      <!-- #endregion::Deleting refund button -->

      <!-- #region::Confirm refund button -->
      <b-col
        v-if="canShowConfirmRefundButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="confirmingRefund"
          @click="onConfirmRefund"
        >
          <b-spinner
            v-if="confirmingRefund"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Recibida</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm refund button -->

    </b-form-row>

    <!-- #region::Stopping refund comment modal -->
    <RefundRejectCommentModal
      v-if="$ability.can('decline', 'Devolution')"
      ref="refundRejectCommentModal"
      @reload-refund="$emit('reload-refund')"
    />
    <!-- #endregion::Stopping refund comment modal -->

    <RefundPaymentModal
      v-if="$ability.can('register refund', 'Devolution')"
      ref="refundPaymentModal"
      @reload-refund="$emit('reload-refund')"
    />

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BButton, BFormRow, BSpinner,
} from 'bootstrap-vue'

import OrderModalHistory from '@/modules/trade/orders/components/modals/OrderModalHistory.vue'
import RefundPaymentModal from '@/modules/store/refunds/components/modals/RefundPaymentModal.vue'
import RefundRejectCommentModal from '@/modules/store/refunds/components/modals/RefundRejectCommentModal.vue'

import getError from '@/helpers/ErrorsHandler'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BCol,
    BButton,
    BSpinner,
    BFormRow,
    OrderModalHistory,
    RefundPaymentModal,
    RefundRejectCommentModal,
  },
  data() {
    return {
      historyStatus: null,
      cancelingRefund: false,
      rejectingRefund: false,
      downloadingFile: false,
      approvingRefund: false,
      confirmingRefund: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    /**
     * Getters del estado de devoluciones
     */
    ...mapGetters({
      getRefund: 'refunds/getRefund',
      getRefundPayments: 'refunds/getRefundPayments',
    }),
    /**
     * Información de la devolución
     */
    refund: {
      get() { return this.getRefund },
    },
    /**
     * Estatus de la devolución
     */
    status: {
      get() { return this.refund.devolution.status.status },
    },
    /**
     * Lista de pagos de la devolución
     */
    refundPayments: {
      get() { return this.getRefundPayments },
    },
    /**
     * Devuelve el estilo de color en base al estado de la devolución
     */
    statusColorStyle() {
      switch (this.status) {
        case 'Solicitud':
          return 'alert-purple'
        case 'Aprobada':
          return 'alert-green'
        case 'Rechazada':
          return 'alert-orange'
        case 'Cancelada':
          return 'alert-gray'
        default:
          return 'alert-blue'
      }
    },

    /**
     * Determina si se puede mostrar el botón para descargar la devolución
     */
    canShowDownloadPdfButton() {
      return this.$ability.can('download', 'Devolution')
    },

    /**
     * Determina si se puede mostrar el boton para cancelar una devolución
     */
    canShowRefundButton() {
      const statuses = ['Recibida']
      return this.$ability.can('register refund', 'Devolution')
        && statuses.includes(this.status)
        && this.refundPayments.length === 0
        && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },

    /**
     * Determina si se puede mostrar el boton para cancelar una devolución
     */
    canShowCancelRefundButton() {
      const statuses = ['Solicitud', 'Aprobada', 'Rechazada']
      return this.$ability.can('cancel', 'Devolution')
        && statuses.includes(this.status)
    },

    /**
     * Determina si se puede mostrar el boton para cancelar una devolución
     */
    canShowConfirmRefundButton() {
      const statuses = ['Aprobada']
      return this.$ability.can('received', 'Devolution')
        && statuses.includes(this.status)
    },

    /**
     * Determina si se puede mostrar el boton para rechazar una devolución
     */
    canShowRejectRefundButton() {
      const statuses = ['Solicitud']
      return this.$ability.can('decline', 'Devolution')
        && statuses.includes(this.status)
        && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },

    /**
     * Determina si se puede mostrar el boton para aprobar una devolución
     */
    canShowApproveRefundButton() {
      const statuses = ['Solicitud']
      return this.$ability.can('confirm', 'Devolution')
        && statuses.includes(this.status)
        && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },

    /**
     * Determina si se puede mostrar el boton para aprobar una devolución
     */
    canShowDeleteRefundButton() {
      const statuses = ['Cancelada']
      return this.$ability.can('delete', 'Devolution')
        && statuses.includes(this.status)
        && this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  watch: {
    refund() {
      this.updateStatusRefund()
    },
  },
  async created() {
    await this.updateStatusRefund()
  },
  methods: {
    ...mapActions({
      cancelRefund: 'refunds/cancelRefund',
      deleteRefund: 'refunds/deleteRefund',
      approveRefund: 'refunds/approveRefund',
      confirmRefund: 'refunds/confirmRefund',
      downloadPDFFile: 'refunds/downloadPDFFile',
      loadStatusRefund: 'refunds/loadStatusRefund',
    }),

    async updateStatusRefund() {
      const response = await this.loadStatusRefund(this.refund.devolution.IdDevolution)
      this.historyStatus = response.data.data
    },

    /**
    * Descarga de la devolución.
    * @summary Descarga el archivo pdf de la devolución
    * @execption Muestra mensaje de error si falla la descarga
    */
    async onDownloadPDF() {
      try {
        this.downloadingFile = true
        await this.downloadPDFFile({ id: this.refund.devolution.IdDevolution, idd: this.refund.devolution.ID })
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.downloadingFile = false
      }
    },

    /**
    * Cancelación de la devolución
    *
    * @summary Confirma la cancelación de la devolución y actualiza la información del estado
    * @execption Muestra mensaje de error si el cambio de estado falla
    */
    async onCancelRefund() {
      // Mesanje de confirmación
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: `¡La devolución #${this.refund.devolution.ID} será cancelada! Esta acción no se podrá deshacer`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      // Si la confirmación es positiva se actualiza en el servidor
      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          this.cancelingRefund = true
          await this.cancelRefund(this.refund.devolution.IdDevolution)

          this.showToast(
            'Devolución cancelada',
            'La devolución ha sido cancelada correctamente.',
            'success',
          )

          this.$emit('reload-refund')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.cancelingRefund = false
        }
      }
    },

    async onRejectRefund() {
      this.$refs.refundRejectCommentModal.showModal(this.refund.devolution.IdDevolution)
    },

    async onDeleteRefund() {
      const result = await this.$swal({
        title: '¿Deseas eliminar la devolución?',
        text: '¡Esta acción no se podrá deshacer!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          await this.deleteRefund(this.refund.devolution.IdDevolution)

          this.showToast(
            'Devolución eliminada',
            'La devolución ha sido eliminada correctamente.',
            'success',
          )

          this.$router.push({ name: 'refunds-list' })
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    async onApproveRefund() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.approvingRefund = true

        const formData = new FormData()
        formData.append('id', this.refund.devolution.IdDevolution)
        await this.approveRefund(formData)

        this.showToast(
          'Devolución confirmada',
          'La devolución ha sido confirmada correctamente.',
          'success',
        )

        this.$emit('reload-refund')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.approvingRefund = false
      }
    },

    async onConfirmRefund() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.confirmingRefund = true
        await this.confirmRefund(this.refund.devolution.IdDevolution)

        this.showToast(
          'Devolución recibida',
          'La devolución ha sido recibida correctamente.',
          'success',
        )

        this.$emit('reload-refund')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.confirmingRefund = false
      }
    },

    /**
    * Mensaje de notificación
    *
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
